<div>
  <div [ngClass]="{ 'flex flex-col gap-8': true }">
    <h3
      [ngClass]="{
        'text-center text-[#717272] text-2xl -tracking-[0.456px] font-bold py-6': true
      }"
    >
      Select Payment Method
    </h3>
    <div>
      <ul [ngClass]="{ 'flex w-full gap-4': true }">
        @for (item of gatewayList; track $index) {
        <li [ngClass]="{ 'flex-1 flex': true }">
          <button
            [ngClass]="{
              'flex-1 py-7 flex flex-col justify-between items-center text-center shadow-[-40px_40px_60px_0px_rgba(190,189,189,0.25)] rounded-lg border-2 border-transparent': true,
              '!border-[#226449]': currentTab === item.slug
            }"
            (click)="handleCardClick(item)"
          >
            <img [ngSrc]="item.asset" width="42" height="42" priority />
            <p [ngClass]="{ 'text-[#226449] text-sm font-bold': true }">
              {{ item?.label }}
            </p>
          </button>
        </li>

        }
      </ul>
    </div>
    <div [ngClass]="{ 'py-[35px] px-10 text-[#717272]': true }">
      <h4
        [ngClass]="{
          'font-bold text-[20px] leading-[150%] text-[#717272] pb-7': true
        }"
      >
        Steps to pay with <span>{{ selectedGateway?.label }}</span>
      </h4>
      <ul [ngClass]="{ 'flex flex-col gap-6 text-[#717272]': true }">
        @for (item of noteList[currentTab]||[]; track $index) {
        <li [ngClass]="{ 'flex items-center gap-1': true }">
          <p [ngClass]="{ 'font-semibold text-base': true }">
            Step {{ $index + 1 }}:
          </p>
          <p>{{ item }}</p>
        </li>
        }
      </ul>
    </div>
    <div [ngClass]="{ 'py-4 flex items-center justify-between': true }">
      <button
        [ngClass]="{
          'w-[264px] capitalize font-lexend !py-4 !font-normal rounded-lg border-2 border-[#DCDCDC]': true,
          'ml-auto': currentTab === 'bank'
        }"
        (click)="viewInvoiceHandler()"
        [disabled]="paymentLinkMutation.isPending()"
      >
        Open Invoice</button
      ><button
        appGeneralButton
        [ngClass]="{
          'max-w-[264px] capitalize font-lexend !py-4 !font-normal disabled:opacity-45': true
        }"
        *ngIf="currentTab !== 'bank'"
        (click)="generatePaymentLink()"
        [disabled]="
          currentTab === 'interswitch' || paymentLinkMutation.isPending()
        "
      >
        {{
          currentTab === "interswitch" ? "Coming soon..." : "Proceed to payment"
        }}
      </button>
    </div>
  </div>
</div>
