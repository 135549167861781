import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { GeneralButtonDirective } from '../../directives/buttons/general-button.directive';
import { Router, RouterModule } from '@angular/router';
import { FormatAmountClass } from '../../../core/utils/formatAmountUtils';
import { injectQueryParams } from 'ngxtension/inject-query-params';

@Component({
  selector: 'app-invoice-success',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    NgOptimizedImage,
    GeneralButtonDirective,
    RouterModule,
  ],
  templateUrl: './invoice-success.component.html',
  styleUrl: './invoice-success.component.css',
})
export class InvoiceSuccessComponent {
  router = inject(Router);
  @Output() onStepChange: EventEmitter<number> = new EventEmitter();
  @Input({ required: true }) totalAmount: number;
  @Input({ required: true }) billReference: string;
  amt = injectQueryParams('amt');

  get formatNumber() {
    return FormatAmountClass.formatAmount;
  }

  viewInvoiceHandler() {
    this.onStepChange.emit(5);
    this.router.navigate([], {
      queryParams: { ref: this.billReference },
      queryParamsHandling: 'merge',
    });
  }
  proceedToPaymentHandler() {
    this.onStepChange.emit(6);
    this.router.navigate([], {
      queryParams: { view: 'payment' },
      queryParamsHandling: 'merge',
    });
  }
}
