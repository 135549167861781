import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Output,
  untracked,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { GeneralInputComponent } from '../../widgets/inputs/general-input/general-input.component';
import { GeneralInputDirective } from '../../directives/inputs/general-input.directive';
import { PasswordInputComponent } from '../../widgets/inputs/password-input/password-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { getLookupStore } from '../../../core/store/selectors/lookup.selectors';
import { LookupService } from '../../../core/services/lookup.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { LookupState } from '../../../core/store/reducers/lookup.reducer';
import { SvgIconComponent } from 'angular-svg-icon';
import { GeneralButtonDirective } from '../../directives/buttons/general-button.directive';
import {
  injectMutation,
  injectQuery,
} from '@tanstack/angular-query-experimental';
import { confirmPasswordValidator } from '../../../validators/confirm-password-validator';
import { IRegisterGuestProps } from '../../../core/models';
import { AuthService } from '../../../core/services/auth.service';
import {
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../../core/store/actions/toast.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-auth-registration-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GeneralInputComponent,
    GeneralInputDirective,
    PasswordInputComponent,
    NgSelectModule,
    SvgIconComponent,
    GeneralButtonDirective,
  ],
  templateUrl: './auth-registration-form.component.html',
  styleUrl: './auth-registration-form.component.css',
})
export class AuthRegistrationFormComponent {
  @Output() onStepChange: EventEmitter<number> = new EventEmitter();
  authForm: FormGroup;
  lookupService = inject(LookupService);
  authService = inject(AuthService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  store = inject(Store<AppState>);

  lookupData: LookupState;

  stateListQueryResponse = injectQuery(() => {
    return {
      queryKey: ['state-list'],
      queryFn: () => this.lookupService.stateLookup(),
      staleTime: Infinity,
      gcTime: Infinity,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    };
  });

  genderQueryResponse = injectQuery(() => {
    return {
      queryKey: ['gender-list'],
      queryFn: () => this.lookupService.genderLookup(),
      staleTime: Infinity,
      gcTime: Infinity,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    };
  });

  taxOfficesQueryResponse = injectQuery(() => {
    return {
      queryKey: ['tax-office-list'],
      queryFn: () => this.lookupService.getTaxStations(),
      staleTime: Infinity,
      gcTime: Infinity,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    };
  });

  lgaQueryResponse = injectQuery(() => {
    return {
      queryKey: ['lga-list'],
      queryFn: () => this.lookupService.lgaLookup(),
      staleTime: Infinity,
      gcTime: Infinity,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    };
  });

  mutation = injectMutation(() => ({
    mutationFn: (data: IRegisterGuestProps) =>
      this.authService.registerGuest(data),
    onSuccess: (data: any) => {
      this.store.dispatch(
        successToastAction({
          autohide: false,
          delay: 7000,
          placement: 'top-end',
          message: 'Account created successfully',
        })
      );

      const { id, ...others } = data?.authentication || {};

      this.authService.setUserData(others);

      this.router.navigate([], {
        queryParams: {
          q: data.userRegistration?.id,
          tpui: data.userRegistration?.tpui,
          email: data.user?.email,
        },
        relativeTo: this.route,
      });
      this.onStepChange.emit(4);
    },
    onError: (error: any) => {
      console.log('error :>> ', error);
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while signing up. Try again shortly',
        })
      );
    },
  }));

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      this.authForm.markAsDirty();
      this.authForm.updateValueAndValidity();
      return;
    }
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Registering account. Please wait...',
      })
    );
    const {
      tin,
      nin,
      genderId,
      taxStationId,
      firstName,
      lastName,
      email,
      phoneNumber,
      addressLine1,
      password,
      confirmPassword,
    } =
      <IRegisterGuestProps & { addressLine1: string }>this.authForm.value || {};
    this.mutation.mutate({
      address: addressLine1,
      email,
      firstName,
      genderId,
      lastName,
      phoneNumber,
      taxStationId,
      tin,
      nin,
      confirmPassword,
      password,
    });
  }

  constructor(private fb: FormBuilder) {
    this.authForm = this.fb.group(
      {
        stateId: this.fb.control(null, [Validators.required]),
        genderId: this.fb.control(null, [Validators.required]),
        lgaId: this.fb.control(null),
        tin: this.fb.control(null),
        email: this.fb.control(null, [Validators.required, Validators.email]),
        phoneNumber: this.fb.control(null, [
          Validators.required,
          Validators.pattern(/^\d{11}$/),
        ]),
        nin: this.fb.control(null, [
          Validators.required,
          Validators.pattern(/^\d{11}$/),
        ]),
        firstName: this.fb.control(null, [Validators.required]),

        lastName: this.fb.control(null, [Validators.required]),
        taxStationId: this.fb.control(null, [Validators.required]),
        addressLine1: this.fb.control(null),
        password: this.fb.control(null, [
          Validators.required,
          //   customPasswordValidator,
        ]),
        confirmPassword: this.fb.control(null, [Validators.required]),
      },
      {
        updateOn: 'change',
        validators: [confirmPasswordValidator],
      }
    );

    effect(() => {
      const stateData = this.stateListQueryResponse?.data();

      untracked(() => {
        const kdData = (stateData || []).find((state) => state.id === 19);
        if (kdData) {
          this.authForm.patchValue({
            stateId: kdData.id,
          });
        }
      });
    });
  }

  ngOnInit(): void {
    this.store.select(getLookupStore).subscribe((lookupStore) => {
      this.lookupData = lookupStore;
      const kdData = (lookupStore.states?.data || []).find(
        (state) => state.id === 19
      );
      if (kdData) {
        this.authForm.patchValue({
          stateId: kdData.id,
        });
      }
    });
  }
}
