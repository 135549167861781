import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, Output, signal } from '@angular/core';
import { GeneralButtonDirective } from '../../directives/buttons/general-button.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import {
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../../core/store/actions/toast.actions';
import { AuthService } from '../../../core/services/auth.service';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import { PaymentService } from '../../../core/services/payment.service';
import { ScriptLoaderService } from '../../../core/services/script-loader.service';
import { environment } from '../../../../environments/environment';
import dayjs from 'dayjs';

interface GatewayProps {
  slug: string;
  label: string;
  asset: string;
  disabled?: boolean;
}

const gateways: GatewayProps[] = [
  {
    slug: 'bank',
    label: 'Bank Branch',
    asset: '/assets/images/payment-gateways/bank-icon.png',
  },
  {
    slug: 'remita',
    label: 'Remita',
    asset: '/assets/images/payment-gateways/remita-icon.png',
  },
  {
    slug: 'interswitch',
    label: 'Interswitch',
    asset: '/assets/images/payment-gateways/interswitch-icon.png',
    disabled: true,
  },
];

const gatewayNotes = {
  remita: [
    "When you click on proceed, you'll be redirected to a secure payment gateway",
    'Select your preferred payment method from the options provided',
    'Follow the prompt and provide all necessary details as it relates to the payment method chosen.',
    'Confirm the payment amount.',
    'Once the payment is processed successfully, you will receive a confirmation and  a receipt is generated',
  ],
  bank: [
    'Download the invoice for the payment you want to make',
    'Visit your Bank Branch of choice',
    'Present your invoice number or invoice',
    'Make the payment to bank teller(via cash or bank transfer)',
    'Receive the receipt as proof of payment',
    'Go to your dashboard to print Paykaduna receipt',
  ],
  interswitch: [
    "When you click on proceed, you'll be redirected to a secure payment gateway",
    'Select your preferred payment method from the options provided',
    'Follow the prompt and provide all necessary details as it relates to the payment method chosen.',
    'Confirm the payment amount.',
    'Once the payment is processed successfully, you will receive a confirmation and  a receipt is generated',
  ],
};

@Component({
  selector: 'app-invoice-payment-page',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, GeneralButtonDirective],
  templateUrl: './invoice-payment-page.component.html',
  styleUrl: './invoice-payment-page.component.css',
})
export class InvoicePaymentPageComponent {
  store = inject(Store<AppState>);
  router = inject(Router);
  route = inject(ActivatedRoute);
  scriptLoader = inject(ScriptLoaderService);
  authService = inject(AuthService);
  paymentService = inject(PaymentService);

  gatewayList = gateways;
  noteList = gatewayNotes;
  currentTab = 'remita';
  ref = signal('');
  @Output() onStepChange: EventEmitter<number> = new EventEmitter();
  selectedGateway: GatewayProps = this.gatewayList.find(
    (gateway) => gateway.slug === this.currentTab
  );

  paymentLinkMutation = injectMutation(() => ({
    mutationFn: (data: ObjectLiteral) =>
      this.paymentService.generatePaymentLink(data),
    onSuccess: (data: any) => {
      this.store.dispatch(
        successToastAction({
          autohide: false,
          delay: 7000,
          placement: 'top-end',
          message:
            'Payment session created successfully. You will be redirected shortly',
        })
      );

      const url = new URL(data?.checkoutUrl);
      const searchParams = url.searchParams;
      const rrr = searchParams.get('rrr');
      const userData = this.authService.userData();

      const paymentDataWithRrr = {
        key: environment.remita_pub_key,
        processRrr: true,
        transactionId: `${this.ref()}_${+dayjs()}`, //you are expected to generate new values for the transactionId for each transaction processing.
        channel:
          'CARD,USSD,TRANSFER,PAYWITHREMITA,PHONENUMBER,WALLET,ENAIRA,BANK,BRANCH,IBANK', //this field is used to filter what card channels you want enabled on the payment modal
        extendedData: {
          customFields: [
            {
              name: 'rrr',
              value: rrr, //rrr to be processed.
            },
          ],
        },
        onSuccess: (response) => {
          console.log('callback Successful Response', response);
          this.router.navigate(['/user/payment-redirect'], {
            queryParams: {
              billRef: this.ref(),
              provider: 'remita',
            },
          });
        },
        onError: (response) => {
          console.log('callback Error Response', response);
        },
        onClose: function () {
          console.log('closed');
        },
      };

      //   console.log('paymentData :>> ', paymentData);

      const handler =
        this.scriptLoader.RmPaymentEngine.init(paymentDataWithRrr);
      handler.showPaymentWidget();

      //old implementation. To be replaced
      //   this.store.dispatch(
      //     successToastAction({
      //       autohide: false,
      //       delay: 7000,
      //       placement: 'top-end',
      //       message:
      //         'Payment session created successfully. You will be redirected shortly',
      //     })
      //   );
      //   window.location.replace(data?.checkoutUrl);
    },
    onError: (error: any) => {
      console.log('error :>> ', error);
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while creating a payment session',
        })
      );
    },
  }));

  handleCardClick(gateway: GatewayProps) {
    this.currentTab = gateway.slug;
    this.selectedGateway = gateway;
  }

  viewInvoiceHandler() {
    this.onStepChange.emit(5);
    this.router.navigate([], {
      queryParams: { ref: this.ref() },
    });
  }

  generatePaymentLink() {
    // if (!!this.scriptLoader.scriptLoadError) {
    //   this.store.dispatch(
    //     errorToastAction({
    //       autohide: true,
    //       delay: 7000,
    //       placement: 'top-end',
    //       message: this.scriptLoader.scriptLoadError,
    //     })
    //   );

    //   return;
    // }
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Creating payment session. Please wait...',
      })
    );
    const userData = this.authService.userData();
    this.paymentLinkMutation.mutate({
      tpui: userData?.tpui,
      billReference: this.ref(),
      provider: this.currentTab,
    });
  }

  ngOnInit(): void {
    // Accessing query parameters from the root
    this.route.queryParams.subscribe((params) => {
      this.ref.set(params['billReference'] || params['ref']);
    });
  }
}
