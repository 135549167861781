<div [ngClass]="{ 'p-6 relative': true }">
  <app-generate-invoice-page
    [ref]="billReference"
    [hideDownloadButton]="true"
  ></app-generate-invoice-page>
</div>

<div
  [ngClass]="{
    'sticky bottom-0 bg-white p-6 flex items-center justify-center gap-4': true
  }"
>
  <button></button
  ><button
    [ngClass]="{
      'font-lexend p-4 rounded-lg bg-[#f7f7f7] flex gap-2 items-center': true
    }"
    (click)="handleDownload()"
  >
    <svg-icon
      src="assets/svgs/back_arrow_icon.svg"
      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
      [ngClass]="{ '[&_path]:fill-[#292D32] -rotate-90': true }"
    ></svg-icon>
    Download</button
  ><button
    appGeneralButton
    [ngClass]="{
      'capitalize font-lexend max-w-[192px] !font-normal text-base': true
    }"
    (click)="proceedToPaymentHandler()"
  >
    Proceed to payment
  </button>
</div>
