import { AbstractControl, ValidationErrors } from '@angular/forms';

export function customNumberValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  if (isNaN(value) || value < 0) {
    return { invalidNumber: true };
  }
  return null;
}
