import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { GeneralButtonDirective } from '../../directives/buttons/general-button.directive';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth-success-modal',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, GeneralButtonDirective],
  templateUrl: './auth-success-modal.component.html',
  styleUrl: './auth-success-modal.component.css',
})
export class AuthSuccessModalComponent {
  route = inject(ActivatedRoute);
  @Output() onProceedToGenerateInvoice: EventEmitter<any> = new EventEmitter();

  email: string;
  tpui: string;

  proceedToGenerateInvoice() {
    this.onProceedToGenerateInvoice.emit();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.email = params?.['email'] || '-';
      this.tpui = params?.['tpui'] || '-';
    });
  }
}

// {
//     "q": "3d3b3f1e-e2d0-4502-998f-4cd7abb750fc",
//     "tpui": "KIR-24KN-000027",
//     "email": "aa@a.aa"
// }
