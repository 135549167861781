<div class="min-w-[618px] mx-auto min-h-[526px] bg-white rounded-[20px] p-8">
    <div class="relative">
        <button type="button" (click)="goBack()" [ngClass]="{'flex items-center gap-2 text-tw-text-color': true}">
            <svg-icon src="assets/svgs/back_arrow_icon.svg" [svgStyle]="{ 'width.px':16,'height.px':16 }"></svg-icon>
            <span class="text-sm font-medium">Go back</span>
        </button>
    </div>
    <!-- Title Section -->
    <div class="w-full">
        <div class="relative flex justify-center w-full pb-8 text-center">
            <img [src]="'assets/images/icon-logo.png'" class="w-[50px] overflow-hidden object-fill" />
        </div>

        <div [ngClass]="{'flex flex-col items-center pb-8': true}">
            <h3 [ngClass]="{'text-[#292929] text-2xl font-bold': true}">Forgot Password</h3>
            <p [ngClass]="{'text-tw-text-color text-base': true}">Reset your password securely and regain access to your
                account.
            </p>
        </div>
    </div>

    <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="flex flex-col flex-1 w-full p-4">

        <div class="flex flex-col gap-6 pb-6 mb-10">

            <div class="w-full mx-auto">
                <label for="" class="flex flex-col">

                    <div class="relative flex w-full">
                        <!-- <input appGeneralInput type='email' placeholder="Enter email" formControlName="email" /> -->
                        <app-general-input label="Email address" type="email" placeholder="Enter email"
                            controlName="email" [formGroup]="authForm"></app-general-input>

                        <div *ngIf="!!authForm.get('email').errors && authForm.get('email').touched"
                            class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]">
                            <span *ngIf="authForm.get('email').errors?.email">{{'Invalid email'}}</span>
                            <span *ngIf="authForm.get('email').errors?.required">Email is required</span>
                        </div>
                    </div>
                </label>
            </div>
        </div>
        <div class="mt-auto text-center">
            <button appGeneralButton type="submit" [disabled]="mutation.isPending()"
                [ngClass]="{'opacity-50': mutation.isPending()}">{{mutation.isPending()?"Resetting password.
                Please
                wait...":"Reset password"}}</button>
        </div>

        <div [ngClass]="{'text-center pt-4': true}"><a href=""
                class="text-sm leading-6 underline text-[#226449] underline-offset-[3px] text-center font-bold"
                [routerLink]="[]" routerLinkActive="bg-slate-200" [routerLinkActiveOptions]="{exact: true}"
                [queryParams]="{a:'login'}">Back to Login</a></div>
    </form>
    <div class="flex items-center justify-center gap-2 py-8 text-center login-notice">
        <p [ngClass]="{'text-tw-text-color font-normal text-base capitalize': true}">Don&apos;t have an account?
        </p> <button [ngClass]="{'text-[#226449] text-base underline font-bold': true}"
            (click)="switchToRegister()">Register</button>
    </div>
</div>
