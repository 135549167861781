import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

const accountTypes = [
  {
    id: 1,
    iconPath: 'assets/svgs/expert-modal/individual.svg',
    text: 'Individual',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
    color: '#FFF7F7',
  },
  {
    id: 2,
    text: 'Corporate',
    iconPath: 'assets/svgs/expert-modal/corporate.svg',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
    color: '#F9F5FF',
  },
  {
    id: 4,
    text: 'Federal Agency',
    iconPath: 'assets/svgs/expert-modal/federal_agency.svg',
    color: '#F8FFF0',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
  },
  {
    id: 3,
    text: 'State Agency',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
    iconPath: 'assets/svgs/expert-modal/state_agency.svg',
    color: '#FFF1FE',
  },
];

@Component({
  selector: 'app-auth-registration-options',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './auth-registration-options.component.html',
  styleUrl: './auth-registration-options.component.css',
})
export class AuthRegistrationOptionsComponent {
  accounTypeList = accountTypes;
  @Output() onStepChange: EventEmitter<number> = new EventEmitter();

  goToRegistrationForm(arg: any) {
    this.onStepChange.emit(3);
  }
}
