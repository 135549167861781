import { Injectable } from '@angular/core';
import { ObjectLiteral } from '../models/ObjectLiteral';

type IframeModal = () => void;

type Props = {
  init: (arg: ObjectLiteral) => { showPaymentWidget: () => void };
};

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private loadedScripts: { [url: string]: boolean } = {};
  RmPaymentEngine: Props;
  scriptLoadError: string;

  constructor() {}

  // Load external script dynamically
  loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.loadedScripts[url]) {
        console.log('SCRIPT ALREADY LOADED');
        resolve(); // If the script is already loaded, resolve immediately
        return;
      }

      const scriptElement = document.createElement('script');
      scriptElement.src = url;
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;

      scriptElement.onload = () => {
        this.loadedScripts[url] = true; // Mark the script as loaded
        this.RmPaymentEngine = (window as any).RmPaymentEngine;
        this.scriptLoadError = '';

        resolve();
      };

      scriptElement.onerror = (error) => {
        reject(`Failed to load script: ${url}`);
        this.scriptLoadError =
          'Unable to load Remita payment gateway. Refresh your browser and try again.';
      };

      document.body.appendChild(scriptElement);
    });
  }
}
