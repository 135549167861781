import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-password-reset-email-notice-ui',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './password-reset-email-notice-ui.component.html',
  styleUrl: './password-reset-email-notice-ui.component.css',
})
export class PasswordResetEmailNoticeUiComponent {}
