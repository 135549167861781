import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { GeneralButtonDirective } from '../../../directives/buttons/general-button.directive';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-successful-notice-ui',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, GeneralButtonDirective],
  templateUrl: './reset-successful-notice-ui.component.html',
  styleUrl: './reset-successful-notice-ui.component.css',
})
export class ResetSuccessfulNoticeUiComponent {
  //Services
  router = inject(Router);
  route = inject(ActivatedRoute);

  switchToLogin() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        a: 'login',
      },
    });
  }
}
