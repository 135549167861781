import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { customNumberValidator } from '../../../validators/custom-number-validator';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@Component({
  selector: 'app-edit-invoice',
  standalone: true,
  imports: [
    CommonModule,
    NzFormModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzInputModule,
    NzInputNumberModule,
  ],
  templateUrl: './edit-invoice.component.html',
  styleUrl: './edit-invoice.component.css',
})
export class EditInvoiceComponent {
  @Input({ required: true }) mdaList: ObjectLiteral[];
  @Input({ required: true }) revenueHeadsAreLoading: boolean = false;
  @Input({ required: true }) revenueHeadsList: ObjectLiteral[];

  @Output() onInvoiceUpdate: EventEmitter<ObjectLiteral> = new EventEmitter();
  @Output() onMdaDataChange: EventEmitter<ObjectLiteral> = new EventEmitter();

  editForm: FormGroup;

  private _invoiceToEdit!: ObjectLiteral | null;

  // Using a setter for @Input to listen for changes
  @Input({ required: true })
  set invoiceToEdit(value: ObjectLiteral | null) {
    this.editForm.patchValue({
      mda: value?.mda || null,
      service: value?.service || null,
      amount: value?.amount || null,
      description: value?.description || null,
      id: value?.id || null,
    });
    this._invoiceToEdit = value;
  }

  get inputValue(): ObjectLiteral | null {
    return this._invoiceToEdit;
  }

  onSubmit() {
    if (!this.editForm.valid) {
      Object.values(this.editForm.controls).forEach((control) => {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();
      });
      return;
    }
    this.onInvoiceUpdate.emit(this.editForm.value);
  }

  constructor(private fb: FormBuilder) {
    this.editForm = this.fb.group({
      id: ['', [Validators.required]],
      mda: ['', [Validators.required]],
      service: ['', [Validators.required]],
      amount: [null, [Validators.required, customNumberValidator]],
      description: [''],
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.editForm.get('mda').valueChanges.subscribe((data) => {
      this.editForm.get('service')?.patchValue(null);
      this.onMdaDataChange.emit(data);
    });
  }
}
