<div class="max-w-[618px] mx-auto max-h-[450px] bg-white rounded-[20px] p-8">

    <div [ngClass]="{'text-center flex justify-center pb-10': true}">
        <span>
            <svg-icon [svgStyle]="{ }" src="assets/svgs/password-reset-email-notice-icon.svg"></svg-icon>
        </span>
    </div>
    <div [ngClass]="{'flex justify-center flex-col gap-2': true}">
        <h5 [ngClass]="{'text-[#0B0808] text-3xl font-medium text-center': true}">
            Check your Email
        </h5>
        <p [ngClass]="{'text-center text-tw-text-color max-w-[412px] mx-auto text-lg': true}">
            We have sent Instructions on how to reset your password to <span
                [ngClass]="{'font-bold': true}">{{'daganalois@gmail.com'}}</span>
        </p>
    </div>
</div>
