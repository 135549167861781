// Landing page tiles data

// /api/LookUps/GetLkMdaCategory

export const sectorsList = [
  {
    openDialogue: true,
    redirectUrl: null,
    id: 1,
    displayField: 'OTHERS',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://kadvreg.net/',
    id: 2,
    displayField: 'MOTOR VEHICLE ADMINISTRATION',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://kaduna.osoftpay.net/',
    id: 4,
    displayField: 'ENVIRONMENT & NATURAL RESOURCES',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://mytax.kadirs.revmateportal.com/login',
    id: 5,
    displayField: 'PERSONAL INCOME TAX & DIRECT ASSESSMENT',
  },
  {
    openDialogue: true,
    redirectUrl: null,
    id: 6,
    displayField: 'MDA COLLECTION',
  },
  {
    openDialogue: true,
    redirectUrl: null,
    id: 7,
    displayField: 'DEVELOPMENT LEVIES, FEES, & OTHERS',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://kssqaa.org/',
    id: 8,
    displayField: 'EDUCATION (FEES & LICENSING)',
  },
  {
    openDialogue: true,
    redirectUrl: null,
    id: 9,
    displayField: 'WITHHOLDING TAX',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://taxpayer-kadirs.aoctms.com.ng/',
    id: 10,
    displayField: 'HOTELS, RESTAURANTS & EVENT CENTRES',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://edutyng.com/kadirs/',
    id: 11,
    displayField: 'STAMP DUTY TAX',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://kadjud.osoftpay.net/',
    id: 12,
    displayField: 'COURT FEES',
  },
  {
    openDialogue: true,
    redirectUrl: null,
    id: 13,
    displayField: 'TERTIARY EDUCATION',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://cashworxportal.net/login',
    id: 14,
    displayField: 'LGA COLLECTIONS',
  },
  {
    openDialogue: true,
    redirectUrl: null,
    id: 15,
    displayField: 'TRANSPORT',
  },
  {
    openDialogue: false,
    redirectUrl: 'https://kadirs-gamingtaxation.com/',
    id: 3,
    displayField: 'BETTING, POOLS & LOTTERY',
  },
];
