<div [ngClass]="{ 'px-4 text-[#717272] pb-[54px]': true }">
  <p [ngClass]="{ 'text-base text-center font-normal pb-4': true }">
    Select preferred category
  </p>
  <ul [ngClass]="{ 'flex gap-2': true }">
    @for (accountType of accounTypeList; track accountType.id) {
    <li [ngClass]="{ 'flex-1 flex': true }">
      <button
        [ngClass]="{
          'text-tw-text-color text-sm font-semibold w-full rounded-lg border border-transparent flex-1 flex flex-col items-center py-[35px] justify-center gap-4 bg-[linear-gradient(0deg,rgba(222,222,222,0.20)_0%,rgba(222,222,222,0.20)_100%)]': true
        }"
        (click)="goToRegistrationForm(accountType.id)"
      >
        <span>
          <svg-icon
            [src]="accountType.iconPath"
            [svgStyle]="{ 'width.px': 57, 'height.px': 57 }"
          ></svg-icon>
        </span>
        <span [ngClass]="{ 'text-sm text-[#717272] font-normal': true }">{{
          accountType.text
        }}</span>
      </button>
    </li>
    }
  </ul>
</div>
