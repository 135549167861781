import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  Output,
} from '@angular/core';
import { FormatAmountClass } from '../../../core/utils/formatAmountUtils';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import {
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../../core/store/actions/toast.actions';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { BillsService } from '../../../core/services/bills.service';
import { LocalService } from '../../../core/services/local.service';

const arr = [
  {
    mda: {
      id: 1,
      name: 'Ministry of Agriculture',
      code: '021500100100',
    },
    service: {
      expertSystem: {
        id: 13,
        name: 'Not Specified',
        redirectUrl: null,
      },
      id: 55,
      revenueCode: '12060031',
      revenueName: 'Sales of Forms For Promotion, Conversion and Confirmation',
      revenueHeadAttributes: [],
      revenueHeadDocuments: [],
    },
    amount: 1220,
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum eveniet dolor soluta laborum, deserunt perferendis in esse adipisci non voluptas dignissimos eligendi iste doloremque? Iusto modi dicta incidunt sit quis!',
  },
  {
    mda: {
      id: 14,
      name: 'Kaduna State Traffic Law Enforcement Agency (KASTLEA)',
      code: '023400600100',
    },
    service: {
      expertSystem: {
        id: 13,
        name: 'Not Specified',
        redirectUrl: null,
      },
      id: 169,
      revenueCode: '12050025',
      revenueName: 'Penalty for Heavy Duty Vehicles',
      revenueHeadAttributes: [],
      revenueHeadDocuments: [],
    },
    amount: 1220,
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorum eveniet dolor soluta laborum, deserunt perferendis in esse adipisci non voluptas dignissimos eligendi iste doloremque? Iusto modi dicta incidunt sit quis!',
  },
];

@Component({
  selector: 'app-invoice-preview',
  standalone: true,
  imports: [CommonModule, NzCollapseModule, SvgIconComponent, NzButtonModule],
  templateUrl: './invoice-preview.component.html',
  styleUrl: './invoice-preview.component.css',
})
export class InvoicePreviewComponent {
  @Input({ required: true }) formValues: ObjectLiteral[];
  arrTest = arr;
  @Input({ required: true }) totalAmount: number;
  @Input({ required: true }) formLength: number;
  @Input({ required: true }) deleteInvoiceInfo: (arg: number) => void;

  activePanel: number = 0;
  @Output() onEditIndex: EventEmitter<number> = new EventEmitter();
  @Output() onAddNewInvoice: EventEmitter<number> = new EventEmitter();
  @Output() onStepChange: EventEmitter<number> = new EventEmitter();

  store = inject(Store<AppState>);
  router = inject(Router);
  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  localService = inject(LocalService);
  billService = inject(BillsService);

  get formatNumber() {
    return FormatAmountClass.formatAmount;
  }
  onPanelClick(event) {
    event.stopPropagation();
  }
  togglePanel(index) {
    this.activePanel =
      this.activePanel !== index || this.activePanel < 0 ? index : -1;
  }

  handleEdit(index: number) {
    this.onEditIndex.emit(index);
  }
  handleAddNewInvoice() {
    this.onAddNewInvoice.emit();
  }

  // API call (Create Bill)
  mutation = injectMutation(() => ({
    mutationFn: (billData: { [key: string]: any }) =>
      this.billService.createCompleteBill(billData),
    onSuccess: (data) => {
      this.router.navigate([], {
        queryParams: {
          billReference: (data as any)?.billReference,
          amt: this.totalAmount,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
        relativeTo: this.route,
      });
      this.onStepChange.emit(4);
      this.store.dispatch(
        successToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: 'Bill created successfully',
        })
      );
    },
    onError: (error: any) => {
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while creating bill. Try again shortly',
        })
      );
    },
  }));

  generateInvoice() {
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Creating a bill. Please wait...',
      })
    );
    const mdaHash: ObjectLiteral = {};
    (this.formValues || []).map((record) => {
      const mdaCode = record?.mda?.code;
      if (!mdaHash[mdaCode]) {
        mdaHash[mdaCode] = {
          description: record?.description,
          billDetails: [
            {
              revenueHeadId: record?.service?.id,
              amount: record?.amount,
            },
          ],
        };
      } else {
        mdaHash[mdaCode].description = record?.description;
        mdaHash[mdaCode].billDetails.push({
          revenueHeadId: record?.service?.id,
          amount: record?.amount,
        });
      }
    });
    const tpui = this.authService.userData()?.tpui;
    const arr = [];
    for (const [mdaCode, value] of Object.entries(mdaHash)) {
      arr.push({
        mdaCode,
        billDetails: value.billDetails,
        description: value.description,
      });
    }
    this.mutation.mutate({
      tpui,
      mdaBills: arr,
    });
  }
}
