import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutsideClickModalDirective } from '../../directives/outside-click-modal.directive';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.css',
  standalone: true,
  imports: [CommonModule, OutsideClickModalDirective],
  host: {},
})
export class ModalComponent {
  @Output() closeModalEvent: EventEmitter<boolean> = new EventEmitter();
  showModal = false;

  closeModalHandler(evt: MouseEvent) {
    const modalEl = (evt.currentTarget as HTMLDivElement).firstElementChild;
    const targetEl = evt.target as HTMLElement;

    if (!modalEl?.contains(targetEl)) {
      this.closeModal();
      return;
    }
    this.openModal();
    // this.openModal = evt;
  }

  openModal() {
    this.showModal = true;
    this.closeModalEvent.emit(false);
  }
  closeModal() {
    this.showModal = false;
    this.closeModalEvent.emit(true);
  }
  toggleModal() {
    this.showModal = !this.showModal;
  }
}
