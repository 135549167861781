<div>
  <div
    [ngClass]="{ 'flex flex-col items-center justify-center gap-6 pb-6': true }"
  >
    <h3
      [ngClass]="{
        'text-[#717272] text-[20px] leading-normal font-bold text-center': true
      }"
    >
      Preview
    </h3>
    <p [ngClass]="{ 'text-[#226449] text-center font-medium text-2xl': true }">
      NGN {{ formatNumber(totalAmount) }}
    </p>
    <p [ngClass]="{ 'text-[#717272] text-base font-bold capitalize': true }">
      Summary
    </p>
  </div>
  <div
    [ngClass]="{
      'w-full max-w-[663px] mx-auto shadow-[-40px_40px_60px_0px_rgba(190,189,189,0.25)] p-6': true
    }"
  >
    <nz-collapse
      nzExpandIconPosition="end"
      [nzGhost]="true"
      [ngClass]="{ '!bg-transparent': true }"
    >
      <div class="flex flex-col gap-5">
        @for (record of formValues; track $index) {

        <div
          [ngClass]="{
            'rounded-lg w-full max-w-[605px] mx-auto p-4 shadow-[0px_20px_40px_0px_rgba(185,185,185,0.25)]': true
          }"
        >
          <ng-template #accordionTitle>
            <div
              [ngClass]="{
                'flex justify-between items-center pl-6 border-b border-[#DCDCDC]': true
              }"
              role="button"
              (click)="onPanelClick($event)"
            >
              <p
                [ngClass]="{
                  'text-base text-[#292929] font-medium flex-1': true
                }"
              >
                {{ record?.service?.revenueName || "" }}
              </p>

              <div [ngClass]="{ 'flex items-center': true }">
                <button
                  type="button"
                  (click)="handleEdit($index)"
                  [ngClass]="{
                    'flex gap-2 items-center text-[#2B4E4C] text-sm': true
                  }"
                  [disabled]="mutation.isPending()"
                >
                  <svg-icon
                    src="assets/svgs/edit-invoice-icon.svg"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                  >
                  </svg-icon>
                  Edit
                </button>
                <hr [ngClass]="{ 'w-4 h-[2px] rotate-90': true }" />

                <button
                  type="button"
                  (click)="deleteInvoiceInfo($index)"
                  [ngClass]="{ 'text-sm text-[#717272]': true }"
                  *ngIf="$index !== 0"
                  [disabled]="mutation.isPending()"
                >
                  Delete
                </button>

                <button
                  type="button"
                  (click)="togglePanel($index)"
                  [ngClass]="{ 'p-2': true }"
                >
                  <svg-icon
                    src="assets/svgs/chevron-down.svg"
                    [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                    [ngClass]="{
                                      'block [&_path]:fill-[#141B34] [&_svg]:!flex [&_svg]:items-center': true,
                                      '-rotate-90': activePanel !== $index,
                                      'rotate-90': activePanel === $index,
                                    }"
                  >
                  </svg-icon>
                </button>
              </div>
            </div>
          </ng-template>
          <nz-collapse-panel
            [nzHeader]="record.service?.revenueName"
            [nzActive]="true"
            [nzDisabled]="false"
            [nzHeader]="accordionTitle"
            [nzShowArrow]="false"
          >
            <div [ngClass]="{ 'flex flex-col gap-4': true }">
              <div [ngClass]="{ 'flex justify-between gap-12': true }">
                <p [ngClass]="{ 'text-[#717272] text-sm text-left': true }">
                  MDA
                </p>
                <p
                  [ngClass]="{
                    'text-[#717272] text-sm font-semibold text-right': true
                  }"
                >
                  {{ record?.mda?.name }}
                </p>
              </div>
              <div [ngClass]="{ 'flex justify-between gap-12': true }">
                <p [ngClass]="{ 'text-[#717272] text-sm text-left': true }">
                  What do you want to pay for
                </p>
                <p
                  [ngClass]="{
                    'text-[#717272] text-sm font-semibold text-right': true
                  }"
                >
                  {{ record?.service?.revenueName }}
                </p>
              </div>
              <div [ngClass]="{ 'flex justify-between gap-12': true }">
                <p [ngClass]="{ 'text-[#717272] text-sm text-left': true }">
                  Amount
                </p>
                <p
                  [ngClass]="{
                    'text-[#717272] text-sm font-semibold text-right': true
                  }"
                >
                  NGN{{ formatNumber(record?.amount || 0) }}
                </p>
              </div>
              <div [ngClass]="{ 'flex justify-between gap-12': true }">
                <p [ngClass]="{ 'text-[#717272] text-sm text-left': true }">
                  Description
                </p>
                <p
                  [ngClass]="{
                    'text-[#717272] text-sm font-semibold text-right': true
                  }"
                >
                  {{ record?.description }}
                </p>
              </div>
            </div>
          </nz-collapse-panel>
        </div>
        }
      </div>
    </nz-collapse>

    <div [ngClass]="{ 'flex items-center justify-between pt-8': true }">
      <button
        [ngClass]="{
          'p-4 border border-dashed flex gap-2 items-center text-[#717272] rounded-lg': true
        }"
        (click)="handleAddNewInvoice()"
        type="button"
        [disabled]="mutation.isPending()"
      >
        <svg-icon
          src="assets/svgs/plus.svg"
          [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
          [ngClass]="{ '[&_path]:fill-[#717272]': true }"
        >
        </svg-icon>
        Add another service
      </button>

      <button
        type="button"
        nz-button
        nzSize="large"
        nzType="primary"
        class="py-4 px-9 !h-[unset]"
        (click)="generateInvoice()"
        [disabled]="mutation.isPending()"
      >
        Generate Invoice
      </button>
    </div>
  </div>
</div>
