<div>
  <div
    [ngClass]="{ 'text-center justify-center items-center flex pt-9': true }"
  >
    <img
      ngSrc="/assets/images/invoice_success.png"
      width="130"
      height="130"
      priority
      [ngClass]="{ 'w-[130px] h-[130px]': true }"
    />
  </div>
  <div
    [ngClass]="{
      'text-[#717272] max-w-[473px] mx-auto text-[20px] leading-normal text-center font-medium pt-[35px]': true
    }"
  >
    Your TPUI
    <span [ngClass]="{ 'text-tw-primary uppercase font-bold': true }">{{
      tpui
    }}</span>
    has been sent to
    <span [ngClass]="{ underline: true }">{{ email }}</span
    >. To access your dashboard, click the link in your email to complete your
    registration.
  </div>

  <div [ngClass]="{ 'flex justify-center pt-10 pb-9': true }">
    <button
      appGeneralButton
      [ngClass]="{ 'capitalize max-w-[188px] font-lexend': true }"
      (click)="proceedToGenerateInvoice()"
    >
      generate invoice
    </button>
  </div>
</div>
