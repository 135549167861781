<nz-modal
  [(nzVisible)]="modalState"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="onCancel()"
  [nzWidth]="step() >= 5 ? 900 : 770"
  [nzTitle]="step() === 2 ? title : null"
>
  <ng-template #title>
    <button
      nz-button
      nzType="link"
      nzSize="small"
      class="flex items-center gap-x-2 mt-[-5px]"
      (click)="onBackClick()"
    >
      <svg-icon
        src="assets/svgs/chevron-down.svg"
        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
      ></svg-icon>
      Back to previous page
    </button>
  </ng-template>
  <div *nzModalContent class="px-5">
    @switch (activeSection) { @case ('mda-collection-section') {
    <div>
      @switch (step()) { @case (2) {
      <ng-container
        [ngTemplateOutlet]="previewStep"
        *ngIf="true"
      ></ng-container>

      }@case (3) {<ng-container
        [ngTemplateOutlet]="editStep"
        *ngIf="true"
      ></ng-container
      >}@case (4) {<ng-container
        [ngTemplateOutlet]="successStep"
        *ngIf="true"
      ></ng-container
      >}@case (5) {
      <ng-container
        [ngTemplateOutlet]="viewInvoiceStep"
        *ngIf="true"
      ></ng-container>
      }@case (6) {

      <ng-container
        [ngTemplateOutlet]="paymentStep"
        *ngIf="true"
      ></ng-container>
      } @default {
      <div class="space-y-10">
        <p class="text-lg font-bold text-center">Generate Invoice</p>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <nz-collapse
            nzExpandIconPosition="end"
            [nzGhost]="true"
            [ngClass]="{ '!bg-transparent': true }"
          >
            <div class="flex flex-col gap-5" formArrayName="invoiceInfo">
              <div
                *ngFor="
                  let invoiceInfo of invoiceInfo.controls;
                  trackBy: trackByFn;
                  let i = index
                "
                [formGroupName]="i"
              >
                <nz-collapse-panel
                  [nzHeader]="accordionTitle"
                  [nzActive]="activePanel === i"
                  [nzShowArrow]="false"
                  [ngClass]="{
                    'border border-[#DCDCDC] !pt-0 rounded-lg': true
                  }"
                >
                  <div
                    nz-form
                    class="w-full p-6 pt-0"
                    nzLayout="vertical"
                    (ngSubmit)="submitInvoice()"
                  >
                    <ng-template #accordionTitle>
                      <div
                        [ngClass]="{
                          'flex justify-between items-center pl-6': true
                        }"
                        role="button"
                        (click)="onPanelClick($event)"
                      >
                        <p
                          [ngClass]="{
                            'text-base text-[#292929] font-bold -tracking-[0.304px]': true
                          }"
                        >
                          {{
                            invoiceInfo.get("service")?.value?.revenueName ||
                              "Form " + (i + 1)
                          }}
                        </p>

                        <div [ngClass]="{ 'flex items-center': true }">
                          <button
                            type="button"
                            (click)="togglePanel(i)"
                            [ngClass]="{ 'p-4': true }"
                          >
                            <svg-icon
                              src="assets/svgs/chevron-down.svg"
                              [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                              [ngClass]="{
                                  'block [&_path]:fill-[#141B34] [&_svg]:!flex [&_svg]:items-center': true,
                                  '-rotate-90': activePanel !== i,
                                  'rotate-90': activePanel === i,
                                }"
                            >
                            </svg-icon>
                          </button>
                          <button
                            type="button"
                            (click)="deleteInvoiceInfo(i)"
                            *ngIf="
                              activePanel !== i && formLength > 1 && i !== 0
                            "
                            [ngClass]="{ 'pr-4': true }"
                          >
                            <svg-icon
                              src="assets/svgs/bin_icon.svg"
                              [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                            >
                            </svg-icon>
                          </button>
                        </div>
                      </div>
                    </ng-template>
                    <nz-form-item [ngClass]="{ relative: true }">
                      <nz-form-label nzFor="mda" nzRequired
                        >Select MDA</nz-form-label
                      >
                      <nz-form-control nzErrorTip="Please input an MDA">
                        <nz-select
                          nzPlaceHolder="Select MDA"
                          nzSize="large"
                          formControlName="mda"
                          [nzLoading]="mdaQueryResponse.isPending()"
                        >
                          @for (mda of mdaQueryResponse?.data() || []; track
                          mda.id) {
                          <nz-option
                            [nzValue]="mda"
                            [nzLabel]="mda.name"
                          ></nz-option>
                          }
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                      <nz-form-label nzFor="service" nzRequired
                        >What do you want to pay for</nz-form-label
                      >
                      <nz-form-control nzErrorTip="Please select a value">
                        <nz-select
                          nzPlaceHolder="Select"
                          nzSize="large"
                          formControlName="service"
                          [nzLoading]="revenueHeadsQueryResponse.isPending()"
                          [nzShowSearch]="true"
                          [nzDisabled]="!this.mdaData()?.id"
                        >
                          @for (option of revenueHeadsQueryResponse.data() ||
                          []; track option.id) {
                          <nz-option
                            [nzValue]="option"
                            [nzLabel]="option.revenueName"
                          ></nz-option>
                          }
                        </nz-select>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                      <nz-form-label nzFor="amount" nzRequired
                        >Amount</nz-form-label
                      >
                      <nz-form-control
                        nzErrorTip="Please input numeric values only"
                      >
                        <nz-input-number
                          nzSize="large"
                          class="w-full"
                          formControlName="amount"
                        >
                        </nz-input-number>
                      </nz-form-control>
                    </nz-form-item>

                    <nz-form-item>
                      <nz-form-label nzFor="description"
                        >Description</nz-form-label
                      >
                      <nz-form-control>
                        <textarea
                          rows="4"
                          nz-input
                          nzSize="large"
                          name="description"
                          formControlName="description"
                          placeholder="Enter Description"
                        ></textarea>
                      </nz-form-control>
                    </nz-form-item>

                    <!-- <div
                        [ngClass]="{ 'flex items-center justify-between': true }"
                      >
                        <button
                          [ngClass]="{
                            'text-[#EA4335] text-base capitalize py-3 px-8 bg-[#FFFBFA] border border-[#E6E6E6] rounded-lg font-lexend': true
                          }"
                          type="button"
                          *ngIf="formLength > 1"
                        >
                          Delete
                        </button>
                        <button
                          [ngClass]="{
                            'text-[#226449] text-base capitalize py-3 px-8 bg-[#DFFFFD] border border-[#E6E6E6] rounded-lg font-lexend ml-auto': true
                          }"
                          type="button"
                          (click)="saveBill($event, invoiceInfo.value)"
                        >
                          Save
                        </button>
                      </div> -->
                  </div>
                </nz-collapse-panel>
              </div>
            </div>
          </nz-collapse>

          <div [ngClass]="{ 'flex items-center justify-between pt-8': true }">
            <button
              [ngClass]="{
                'p-4 border border-dashed flex gap-2 items-center text-[#717272] rounded-lg': true
              }"
              (click)="addNewInvoiceInfo()"
              type="button"
            >
              <svg-icon
                src="assets/svgs/plus.svg"
                [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
                [ngClass]="{ '[&_path]:fill-[#717272]': true }"
              >
              </svg-icon>
              Add another service
            </button>

            <button
              type="submit"
              nz-button
              nzSize="large"
              nzType="primary"
              class="w-full max-w-[121px] p-4 !h-[unset]"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
      } }
    </div>
    } @case ('auth-section') {
    <div>
      <div
        [ngClass]="{
          'flex items-center justify-center flex-col py-9 gap-4': true
        }"
        *ngIf="![3, 4].includes(authStep())"
      >
        <!-- assets/svgs/mda-collection-icon.svg -->
        <span [ngClass]="{ 'p-[10px] rounded-[100px] bg-[#F9F5FF]': true }">
          <svg-icon
            src="assets/svgs/mda-collection-icon.svg"
            [svgStyle]="{ 'width.px': 68, 'height.px': 68 }"
          >
          </svg-icon>
        </span>
        <span
          [ngClass]="{
            'font-bold text-[#717272] leading-normal text-[20px]': true
          }"
          >MDA Collection</span
        >
      </div>
      @switch (authStep()) { @case (2) {
      <ng-container [ngTemplateOutlet]="regOptions"></ng-container>
      } @case (3) {

      <ng-container [ngTemplateOutlet]="regForm"></ng-container>

      } @case (4) {
      <ng-container [ngTemplateOutlet]="regSuccess"></ng-container>
      <!-- regSuccess -->
      } @default {
      <ng-container [ngTemplateOutlet]="tpuiStep"></ng-container>
      } }
    </div>
    }@default { } }
  </div>
</nz-modal>

<!-- Preview step -->
<ng-template #previewStep>
  <app-invoice-preview
    [formValues]="formData()"
    [totalAmount]="totalAmount()"
    [deleteInvoiceInfo]="deleteInvoiceInfo"
    [formLength]="formLength"
    (onEditIndex)="editInvoice($event)"
    (onAddNewInvoice)="handleAddNewInvoice()"
    (onStepChange)="handleStepChange($event)"
  ></app-invoice-preview>
</ng-template>

<!-- Edit form -->
<ng-template #editStep>
  <app-edit-invoice
    [mdaList]="mdaQueryResponse.data()"
    [revenueHeadsList]="revenueHeadsQueryResponse.data()"
    [revenueHeadsAreLoading]="revenueHeadsQueryResponse.isPending()"
    (onInvoiceUpdate)="updateInvoice($event)"
    (onMdaDataChange)="handleMdaUpdate($event)"
    [invoiceToEdit]="invoiceToEdit()"
  ></app-edit-invoice>
</ng-template>

<!-- Bill creation success screen -->
<ng-template #successStep>
  <app-invoice-success
    (onStepChange)="handleStepChange($event)"
    [totalAmount]="totalAmount()"
    [billReference]="billReference()"
  ></app-invoice-success>
</ng-template>

<!-- View Invoice screen -->
<ng-template #viewInvoiceStep>
  <app-view-invoice [billReference]="billReference()"></app-view-invoice>
</ng-template>

<!-- Payment gateway selection screen -->
<ng-template #paymentStep>
  <app-invoice-payment-page
    (onStepChange)="handleStepChange($event)"
  ></app-invoice-payment-page>
</ng-template>

<!-- TPUI input screen -->
<ng-template #tpuiStep>
  <div class="basic-form" [ngClass]="{ 'max-w-[697px]': true }">
    <div [ngClass]="{ 'w-full max-w-[434px] mx-auto': true }">
      <p
        [ngClass]="{
          'text-center text-tw-text-color text-sm font-medium pb-2': true
        }"
      >
        Input your TPUI (Tax Payers Unique Index)
      </p>
      <div
        [ngClass]="{
          'bg-[#F4F4F4] w-full flex border border-transparent rounded-[10px] p-[2px]': true
        }"
      >
        <input
          type="text"
          [ngClass]="{
            'flex-1 py-4 px-3 bg-transparent outline-none text-tw-text-color text-sm': true
          }"
          placeholder="TPUI (tax payers unique Index)"
          [(ngModel)]="userTPUI"
          (ngModelChange)="handleTPUIChange($event)"
        />
        <button
          [ngClass]="{
            'w-[66px] bg-[#F7A23E] rounded-lg flex justify-center items-center disabled:opacity-50': true
          }"
          (click)="handleUserSearch()"
          [disabled]="userLoading || !userTPUI"
          type="button"
        >
          <svg-icon
            src="assets/svgs/arrow_forward.svg"
            [svgStyle]="{ 'width.px': 14, 'height.px': 22 }"
          >
          </svg-icon>
        </button>
      </div>
      <p
        [ngClass]="{ 'pt-[65px] text-sm text-tw-text-color text-center': true }"
      >
        Don&apos;t have an TPUI?
      </p>
      <div [ngClass]="{ 'text-center pt-4': true }">
        <button
          type="button"
          [ngClass]="{
            'rounded-lg text-white py-4 text-base capitalize w-full max-w-[208px] mx-auto': true
          }"
          appGeneralButton
          (click)="handleAuthStepChange(2)"
        >
          register now
        </button>
      </div>
      <div>
        <p [ngClass]="{ 'pt-4 text-center text-tw-text-color text-sm': true }">
          "Please note that this registration is a one-time process; your TPUI
          Number will serve as the access credential for other tax payment
          services."
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #regOptions>
  <app-auth-registration-options
    (onStepChange)="handleAuthStepChange($event)"
  ></app-auth-registration-options>
</ng-template>
<ng-template #regForm>
  <app-auth-registration-form
    (onStepChange)="handleAuthStepChange($event)"
  ></app-auth-registration-form>
</ng-template>
<ng-template #regSuccess>
  <app-auth-success-modal
    (onProceedToGenerateInvoice)="handleGenerateInvoiceReset()"
  ></app-auth-success-modal>
</ng-template>
