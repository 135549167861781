import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  CommonModule,
  isPlatformBrowser,
  Location,
  NgOptimizedImage,
} from '@angular/common';

import { SvgIconComponent } from 'angular-svg-icon';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralButtonDirective } from '../../directives/buttons/general-button.directive';
import { AuthService } from '../../../core/services/auth.service';
import { ModalComponent } from '../modal/modal.component';
import { RegisterUiComponent } from './register-ui/register-ui.component';
import { LoginUiComponent } from './login-ui/login-ui.component';
import { ForgotPasswordUiComponent } from './forgot-password-ui/forgot-password-ui.component';
import { PasswordResetEmailNoticeUiComponent } from './password-reset-email-notice-ui/password-reset-email-notice-ui.component';
import { ResetSuccessfulNoticeUiComponent } from './reset-successful-notice-ui/reset-successful-notice-ui.component';
import { ResetPasswordUiComponent } from './reset-password-ui/reset-password-ui.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { setStateAction } from '../../../core/store/actions/settings.actions';

type DisplayProp =
  | 'register'
  | 'login'
  | 'forgot-password'
  | 'reset-password'
  | 'reset-message'
  | 'reset-success';

const accountTypes = [
  {
    id: 1,
    iconPath: 'assets/svgs/expert-modal/individual.svg',
    text: 'Individual',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
    color: '#FFF7F7',
  },
  {
    id: 2,
    text: 'Corporate',
    iconPath: 'assets/svgs/expert-modal/corporate.svg',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
    color: '#F9F5FF',
  },
  {
    id: 4,
    text: 'Federal Agency',
    iconPath: 'assets/svgs/expert-modal/federal_agency.svg',
    color: '#F8FFF0',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
  },
  {
    id: 3,
    text: 'State Agency',
    description: `These individuals are obligated to pay taxes on various sources
of income, including personal
earnings, freelance work, and rental income`,
    iconPath: 'assets/svgs/expert-modal/state_agency.svg',
    color: '#FFF1FE',
  },
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    MatIconModule,
    GeneralButtonDirective,
    ModalComponent,
    RegisterUiComponent,
    LoginUiComponent,
    ForgotPasswordUiComponent,
    PasswordResetEmailNoticeUiComponent,
    ResetSuccessfulNoticeUiComponent,
    ResetPasswordUiComponent,
    NgOptimizedImage,
  ],
})
export class HeaderComponent implements OnInit {
  authService = inject(AuthService);
  matIconregistry = inject(MatIconRegistry);
  domSanitizer = inject(DomSanitizer);
  router = inject(Router);
  route = inject(ActivatedRoute);
  location = inject(Location);
  store = inject(Store<AppState>);
  currentPath: string[];

  @Input() hideRoutes = false;
  @ViewChild(ModalComponent) modal: ModalComponent;
  accounTypeList = signal(accountTypes);
  activeDisplay: DisplayProp;

  navLists = [
    {
      name: 'Home',
      link: '/',
      type: 'All',
    },
    {
      name: 'About us',
      link: '/about-us',
      type: 'All',
    },
    {
      name: 'E-services',
      link: '/e-services',
      type: 'All',
    },
    {
      name: 'ConsultConnect',
      link: '/consult-connect',
      type: 'All',
    },
    {
      name: 'Agent Network Services',
      link: '/agent-network',
      type: 'All',
    },
    {
      name: 'Contact us',
      link: '/contact-us',
      type: 'All',
    },
    // {
    //   name: 'What we offer',
    //   link: '/what-we-offer',
    //   type: 'All',
    // },
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.matIconregistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svgs/logo_svg.svg'
      )
    );
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.route.url.subscribe((data) => {
      this.currentPath = data.map((item) => item.path);
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.route.queryParams.subscribe((queryParam) => {
      if (queryParam.a) {
        Promise.resolve().then(() => {
          if (isPlatformBrowser(this.platformId)) {
            this.modal?.openModal();
            this.activeDisplay = queryParam.a;
          }
        });
        return;
      }
      Promise.resolve().then(() => {
        if (isPlatformBrowser(this.platformId)) {
          this.modal?.closeModal();
        }
      });
    }); // you can also do this in ngOnInit
  }

  logoutHandler() {
    this.authService.clearUserData();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }
  openSignUpPopup() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        a: 'register',
      },
    });
  }

  gotoDashboard() {
    const userRole = this.authService.userRole;
    const adminRoleList = ['SuperAdmin', 'Admin', 'Administrator'];
    const expertSystemRoleList = ['Expert System'];
    const governorRoleList = ['Governor'];

    if (userRole === 'Individual') {
      this.router.navigate(['/user/dashboard']);
      return;
    }
    if (adminRoleList.includes(userRole)) {
      this.router.navigate(['/admin']);
    }
    if (governorRoleList.includes(userRole)) {
      this.router.navigate(['/gov_dashboard']);
    }
    if (expertSystemRoleList.includes(userRole)) {
      this.router.navigate(['/expert-system/invoice-reports']);
    }
  }

  clickOut(closeModal: boolean) {
    if (closeModal && this.currentPath && !this.currentPath.length) {
      const { a, ...others } = this.route.snapshot.queryParams;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ...others },
      });
    }
  }

  goBack() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: null,
    });
  }

  switchToLogin() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        a: 'login',
      },
    });
  }

  toggleSideMenuVisible() {
    this.store.dispatch(setStateAction({ publicSideMenuVisible: true }));
  }
}
