import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { PasswordInputComponent } from '../../inputs/password-input/password-input.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { GeneralInputComponent } from '../../inputs/general-input/general-input.component';
import { GeneralInputDirective } from '../../../directives/inputs/general-input.directive';
import { GeneralButtonDirective } from '../../../directives/buttons/general-button.directive';
import { AuthService } from '../../../../core/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store';
import { injectMutation } from '@tanstack/angular-query-experimental';
import {
  clearToastAction,
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../../../core/store/actions/toast.actions';
import { customPasswordValidator } from '../../../../validators/custom-password-validator';

@Component({
  selector: 'app-reset-password-ui',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    ReactiveFormsModule,
    PasswordInputComponent,
    RouterModule,
    GeneralInputComponent,
    GeneralInputDirective,
    GeneralButtonDirective,
  ],
  templateUrl: './reset-password-ui.component.html',
  styleUrl: './reset-password-ui.component.css',
})
export class ResetPasswordUiComponent {
  //Services
  router = inject(Router);
  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  store = inject(Store<AppState>);

  //Form control
  authForm = new FormGroup(
    {
      password: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      confirmPassword: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      token: new FormControl(null, {
        validators: [Validators.required],
      }),
    },
    {
      validators: [customPasswordValidator],
      updateOn: 'change',
    }
  );

  mutation = injectMutation(() => ({
    mutationFn: (data: any) => this.authService.resetPassword(data),
    onSuccess: (data: any) => {
      this.store.dispatch(
        successToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: 'Password successfully reset',
        })
      );
      this.authForm.reset();
      this.switchToLogin();
    },
    onError: (err) => {
      console.log('err :>> ', err);
      const error = (err as any)?.error?.message;
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: error || 'Unable to reset password. Try again',
        })
      );
    },
  }));

  onSubmit() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Resetting password. Please wait...',
      })
    );
    this.mutation.mutate(this.authForm.value);
  }

  switchToLogin() {
    this.router.navigate(['/'], {
      queryParams: {
        a: 'login',
      },
    });
  }

  goBack() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: null,
    });
  }
}
