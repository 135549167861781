<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  nz-form
  nzLayout="vertical"
  class="w-full p-6 pt-0"
>
  <h3 [ngClass]="{ 'text-[#292929] text-xl font-bold pb-6 text-center': true }">
    Edit Invoice Information
  </h3>
  <nz-form-item [ngClass]="{ relative: true }">
    <nz-form-label nzFor="mda" nzRequired>Select MDA</nz-form-label>
    <nz-form-control nzErrorTip="Please input an MDA">
      <nz-select
        nzPlaceHolder="Select MDA"
        nzSize="large"
        formControlName="mda"
        [nzLoading]="!mdaList"
      >
        @for (mda of mdaList || []; track mda.id) {
        <nz-option [nzValue]="mda" [nzLabel]="mda.name"></nz-option>
        }
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="service" nzRequired
      >What do you want to pay for</nz-form-label
    >
    <nz-form-control nzErrorTip="Please select a value">
      <nz-select
        nzPlaceHolder="Select"
        nzSize="large"
        formControlName="service"
        [nzLoading]="revenueHeadsAreLoading"
        [nzShowSearch]="true"
        [nzDisabled]="!editForm?.get('mda')?.value?.id"
      >
        @for (option of revenueHeadsList || []; track option.id) {
        <nz-option
          [nzValue]="option"
          [nzLabel]="option.revenueName"
        ></nz-option>
        }
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="amount" nzRequired>Amount</nz-form-label>
    <nz-form-control nzErrorTip="Please input numeric values only">
      <nz-input-number nzSize="large" class="w-full" formControlName="amount">
      </nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="description">Description</nz-form-label>
    <nz-form-control>
      <textarea
        rows="4"
        nz-input
        nzSize="large"
        name="description"
        formControlName="description"
        placeholder="Enter Description"
      ></textarea>
    </nz-form-control>
  </nz-form-item>

  <div [ngClass]="{ 'flex items-center justify-between': true }">
    <button
      [ngClass]="{
        'text-[#EA4335] text-base capitalize py-3 px-8 bg-[#FFFBFA] border border-[#E6E6E6] rounded-lg font-lexend': true
      }"
      type="button"
    >
      Cancel
    </button>
    <button
      [ngClass]="{
        'text-[#226449] text-base capitalize py-3 px-8 bg-[#DFFFFD] border border-[#E6E6E6] rounded-lg font-lexend ml-auto': true
      }"
      type="submit"
    >
      Update
    </button>
  </div>
</form>
