import { injectQueryParams } from 'ngxtension/inject-query-params';
import { CommonModule, formatCurrency } from '@angular/common';
import {
  Component,
  computed,
  effect,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  untracked,
  WritableSignal,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { AppState } from '../../core/store';
import { getInvoiceStore } from '../../core/store/selectors/invoice.selectors';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import { mdaLookupAction } from '../../core/store/actions/lookup.actions';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActivatedRoute, Router } from '@angular/router';
import { setStateAction } from '../../core/store/actions/invoice.actions';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { LookupService } from '../../core/services/lookup.service';
import { sectorsList } from '../../core/utils/revenueHeadStaticData';
import { BillsService } from '../../core/services/bills.service';
import {
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../core/store/actions/toast.actions';
import { AuthService } from '../../core/services/auth.service';
import { GeneralButtonDirective } from '../directives/buttons/general-button.directive';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ObjectLiteral } from '../../core/models/ObjectLiteral';
import { customNumberValidator } from '../../validators/custom-number-validator';
import { FormatAmountClass } from '../../core/utils/formatAmountUtils';
import { InvoicePreviewComponent } from './invoice-preview/invoice-preview.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { InvoiceSuccessComponent } from './invoice-success/invoice-success.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { GenerateInvoicePageComponent } from '../../pages/user-protected/generate-invoice-page/generate-invoice-page.component';
import { InvoicePaymentPageComponent } from './invoice-payment-page/invoice-payment-page.component';
import { AuthRegistrationOptionsComponent } from './auth-registration-options/auth-registration-options.component';
import { AuthRegistrationFormComponent } from './auth-registration-form/auth-registration-form.component';
import { AuthSuccessModalComponent } from './auth-success-modal/auth-success-modal.component';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-invoice-modal',
  standalone: true,
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzInputModule,
    NzInputNumberModule,
    NzGridModule,
    SvgIconComponent,
    GeneralButtonDirective,
    NzCollapseModule,
    InvoicePreviewComponent,
    EditInvoiceComponent,
    InvoiceSuccessComponent,
    ViewInvoiceComponent,
    GenerateInvoicePageComponent,
    InvoicePaymentPageComponent,
    AuthRegistrationOptionsComponent,
    AuthRegistrationFormComponent,
    AuthSuccessModalComponent,
  ],
  templateUrl: './invoice-modal.component.html',
  styleUrl: './invoice-modal.component.css',
})
export class InvoiceModal implements OnInit, OnDestroy {
  store = inject(Store<AppState>);
  router = inject(Router);
  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  userService = inject(UserService);
  billService = inject(BillsService);
  lookupService = inject(LookupService);
  fb = inject(FormBuilder);
  activePanel: number = 0;
  invoiceStoreSub: Subscription;
  mdaStoreSub: Subscription;
  form: FormGroup;
  formLength: number = 1;
  formData = signal<ObjectLiteral[]>([]);
  modalState = false;
  userTPUI = '';
  userTPUISignal = signal('');
  step = signal(1);
  authStep = signal(1);
  mdaLoading = false;
  editIndex: number;
  billReference = signal<string>('');
  mdaList = sectorsList.filter((sector) => sector.id === 6);
  mdaData: WritableSignal<{ id: number; name: string; code: string } | null> =
    signal(null);
  routeDirection = signal<string>('');
  invoiceToEdit = signal<ObjectLiteral | null>(null);
  @Input({ required: true }) activeSection:
    | 'auth-section'
    | 'mda-collection-section'
    | '';

  totalAmount = computed(() => {
    return (this.formData() || []).reduce((acc, curr) => {
      if (!curr) return acc;
      return acc + (curr?.amount || 0);
    }, 0);
  });

  userQueryResponse = injectQuery(() => {
    return {
      queryKey: ['user-by-tpui', this.userTPUISignal()],
      queryFn: ({ queryKey }) => {
        const [, tpui] = queryKey;
        return this.userService.getUserByTPUI({
          tpui,
        });
      },
      enabled: false,
      gcTime: 0,
      staleTime: 0,
    };
  });
  userLoading =
    this.userQueryResponse.isPending() &&
    this.userQueryResponse.fetchStatus() === 'fetching';

  mdaQueryResponse = injectQuery(() => {
    return {
      queryKey: ['mda-by-category-slug', 'mda-collection'],
      queryFn: ({ queryKey }) => {
        const [, slug] = queryKey;
        return this.lookupService.getMdaByCategorySlug({
          categorySlug: slug,
        });
      },
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    };
  });

  revenueHeadsQueryResponse = injectQuery(() => {
    return {
      queryKey: ['revenue-heads-by-slug', this.mdaData()?.id],
      queryFn: ({ queryKey }) => {
        const [, mdaId] = queryKey;
        return this.lookupService.getRevenueHeadsByMda({
          mdaId,
        });
      },
      enabled: !!this.mdaData()?.id,
      staleTime: Infinity,
      gcTime: Infinity,
    };
  });

  onPanelClick(event) {
    event.stopPropagation(); // Prevent the default toggle behavior on header click
  }
  togglePanel(index) {
    this.activePanel =
      this.activePanel !== index || this.activePanel < 0 ? index : -1;
  }

  handleTPUIChange(event: string) {
    this.userTPUISignal.set(event);
  }

  handleUserSearch() {
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Fetching user information. Please wait...',
      })
    );

    this.userQueryResponse
      .refetch()
      .then((data) => {
        this.store.dispatch(
          successToastAction({
            autohide: false,
            delay: 7000,
            placement: 'top-end',
            message: 'User information retrieved successfully',
          })
        );
        const { id, ...others } = data?.data?.authentication || {};
        this.authService.setUserData(others);

        const role = (others?.roles || [])[0];
        this.handleGenerateInvoiceReset();
      })
      .catch((error) => {
        console.log('error :>> ', error);
        this.store.dispatch(
          errorToastAction({
            autohide: true,
            delay: 7000,
            placement: 'top-end',
            message:
              error?.error?.message ||
              'An error occurred while retrieving user information. Try again shortly',
          })
        );
      });
  }

  get formatNumber() {
    return FormatAmountClass.formatAmount;
  }

  onMdaFocus() {
    this.store.dispatch(mdaLookupAction(null));
  }

  onCancel(): void {
    this.modalState = false;
    this.store.dispatch(setStateAction({ modalState: false }));
    this.router.navigate([], {
      queryParams: null,
    });
  }

  onBackClick() {
    if (this.step() < 2) {
      this.step.set(1);
    } else {
      this.step.set(this.step() - 1);
    }
  }

  submitInvoice() {
    if (!this.form.valid) {
      Object.values(this.form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    } else {
      this.step.set(this.step() + 1);
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      const formArray = this.form.get('invoiceInfo') as FormArray;
      Object.values(formArray.controls).forEach((control: FormGroup) => {
        if (control.invalid) {
          const innerControls = control.controls;
          Object.values(innerControls).forEach((ctrl) => {
            ctrl.markAllAsTouched();
            ctrl.markAsDirty();
            ctrl.updateValueAndValidity({ onlySelf: true });
          });
        }
      });
      return;
    }

    const formArray = this.form.get('invoiceInfo') as FormArray;
    const formValues = Object.values(formArray.controls).map(
      (control: FormGroup) => {
        return control.value;
      }
    );

    this.formData.set(formValues);
    this.step.set(2);
    // this.changeCurrentStep.emit(4);
  }

  goToInvoicePage() {
    this.routeDirection.set('invoice-page');
    this.onSummaryClick();
  }

  goToCheckout() {
    this.routeDirection.set('checkout-page');
    this.onSummaryClick();
  }

  onSummaryClick() {
    // todo: Figure a way to make checkout page get datas from modal
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Creating a bill. Please wait...',
      })
    );
  }

  get invoiceInfo() {
    return this.form.get('invoiceInfo') as FormArray;
  }

  editInvoice(index: number) {
    const invoice = this.invoiceInfo.at(index) as FormGroup;
    this.invoiceToEdit.set(invoice.value);
    this.editIndex = index;
    this.step.set(3);
  }

  updateInvoice(data: ObjectLiteral) {
    const item = this.invoiceInfo.at(this.editIndex) as FormGroup;
    // Modify just the name field
    item?.setValue(data);
    const formValues = Object.values(this.invoiceInfo.controls).map(
      (control: FormGroup) => {
        return control.value;
      }
    );

    this.formData.set(formValues);
    this.step.set(2);
  }

  addNewInvoiceInfo() {
    const uuid = self.crypto.randomUUID();
    const invoiceGroup = this.fb.group({
      id: [uuid, [Validators.required]],
      mda: ['', [Validators.required]],
      service: ['', [Validators.required]],
      amount: [null, [Validators.required, customNumberValidator]],
      description: [''],
    });
    this.invoiceInfo.push(invoiceGroup);

    //Set active panel
    this.activePanel = this.invoiceInfo.length - 1;
    this.formLength = this.invoiceInfo.length;

    invoiceGroup.get('mda').valueChanges.subscribe((data) => {
      this.mdaData.set(data as any);
      invoiceGroup.get('service')?.patchValue(null);
    });
  }

  handleAddNewInvoice() {
    this.addNewInvoiceInfo();
    this.step.set(1);
  }
  handleStepChange(arg: number) {
    this.step.set(arg);
  }
  handleAuthStepChange(arg: number) {
    this.authStep.set(arg);
  }
  handleGenerateInvoiceReset() {
    this.step.set(1);
    this.authStep.set(1);
    this.activeSection = 'mda-collection-section';
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: null,
    });
  }

  deleteInvoiceInfo(index: number) {
    this.invoiceInfo.removeAt(index);
    this.invoiceInfo.updateValueAndValidity();
    this.formLength = this.invoiceInfo.length;
  }

  getGroup(index: number) {
    return this.invoiceInfo.at(index) as FormGroup;
  }
  handleMdaUpdate(arg: ObjectLiteral) {
    this.mdaData.set(arg as any);
  }

  trackByFn(index: number, invoice: ObjectLiteral): number {
    return invoice.id;
  }

  ngOnInit(): void {
    const uuid = self.crypto.randomUUID();
    const invoiceGroup = this.fb.group({
      mda: ['', [Validators.required]],
      service: ['', [Validators.required]],
      amount: [null, [Validators.required, customNumberValidator]],
      description: [''],
      id: [uuid, [Validators.required]],
    });
    this.invoiceInfo.push(invoiceGroup);

    this.invoiceStoreSub = this.store
      .pipe(select(getInvoiceStore))
      .subscribe((invoiceStore) => {
        this.modalState = invoiceStore.modalState;
        if (!!invoiceStore.step) {
          this.step.set(invoiceStore.step);
        }
      });

    invoiceGroup.get('mda').valueChanges.subscribe((data) => {
      this.mdaData.set(data as any);
      invoiceGroup.get('service')?.patchValue(null);
    });

    this.route.queryParams.subscribe((params) => {
      const billRef = params['billReference'];
      const invoiceRef = params['ref'];
      const currentView = params['view'];

      switch (true) {
        case currentView === 'payment': {
          this.step.set(6);
          this.store.dispatch(setStateAction({ modalState: true }));
          this.billReference.set(invoiceRef || billRef);

          return;
        }
        case !!invoiceRef: {
          this.step.set(5);
          this.store.dispatch(setStateAction({ modalState: true }));
          this.billReference.set(invoiceRef);

          return;
        }
        case !!billRef: {
          this.step.set(4);
          this.store.dispatch(setStateAction({ modalState: true }));
          this.billReference.set(billRef);

          return;
        }

        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.invoiceStoreSub) this.invoiceStoreSub.unsubscribe();
    if (this.mdaStoreSub) this.mdaStoreSub.unsubscribe();
    this.store.dispatch(setStateAction({ modalState: false }));
    this.step.set(1);
    this.authStep.set(1);
    this.activeSection = '';
  }

  constructor() {
    this.form = this.fb.group({
      invoiceInfo: this.fb.array([]),
    });
  }
}
