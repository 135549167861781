<div class="min-w-[618px] mx-auto min-h-[450px] bg-white rounded-[20px] p-8">

    <div [ngClass]="{'text-center flex justify-center pb-10': true}">
        <span>
            <svg-icon [svgStyle]="{ }" src="assets/svgs/password-reset-success-icon.svg"></svg-icon>
        </span>
    </div>
    <div [ngClass]="{'flex justify-center flex-col gap-2': true}">
        <h5 [ngClass]="{'text-[#0B0808] text-3xl font-medium text-center': true}">
            Password Reset Successful
        </h5>
        <p [ngClass]="{'text-center text-tw-text-color max-w-[412px] mx-auto text-base': true}">
            Your password has been reset successfully
        </p>
    </div>
    <div [ngClass]="{'pt-8 text-center': true}">
        <button appGeneralButton type="button" [ngClass]="{'max-w-[194px] mx-auto': true}"
            (click)="switchToLogin()">Login</button>
    </div>
</div>
