<div>
  <div [ngClass]="{ 'flex flex-col gap-4 items-center pb-6': true }">
    <img
      [src]="'assets/images/icon-logo.png'"
      class="w-[50px] overflow-hidden object-fill"
    />
    <h3 [ngClass]="{ ' text-[#292929] text-2xl font-bold': true }">Register</h3>
    <p [ngClass]="{ 'text-tw-text-color text-sm font-normal': true }">
      Please provide Personal information
    </p>
  </div>
  <form
    [formGroup]="authForm"
    [ngClass]="{ 'flex flex-col gap-6': true }"
    (ngSubmit)="onSubmit($event)"
    autocomplete="off"
  >
    <div [ngClass]="{ 'flex items-center justify-center gap-4 mt-4': true }">
      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <app-general-input
          label="First Name"
          type="text"
          placeholder="Enter first name"
          controlName="firstName"
          [formGroup]="authForm"
          [required]="true"
        ></app-general-input>
        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('firstName').errors &&
            (authForm.get('firstName').touched ||
              authForm.get('firstName').dirty)
          "
        >
          <span *ngIf="authForm.get('firstName').errors?.required"
            >First name is required</span
          >
        </div>
      </div>
      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <app-general-input
          label="Last Name"
          type="text"
          placeholder="Enter last name"
          controlName="lastName"
          [formGroup]="authForm"
          [required]="true"
        ></app-general-input>
        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('lastName').errors &&
            (authForm.get('lastName').touched || authForm.get('lastName').dirty)
          "
        >
          <span *ngIf="authForm.get('lastName').errors?.required"
            >Last name is required</span
          >
          <span *ngIf="authForm.get('lastName').errors?.pattern"
            >Unique identifier must be 11 digits.</span
          >
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'flex items-center justify-center gap-4 mt-4': true }">
      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <app-general-input
          label="Email Address"
          type="email"
          placeholder="Enter email address"
          controlName="email"
          [formGroup]="authForm"
          [required]="true"
        ></app-general-input>
        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('email').errors &&
            (authForm.get('email').touched || authForm.get('email').dirty)
          "
        >
          <span *ngIf="authForm.get('email').errors?.required"
            >Email is required</span
          >
          <span *ngIf="authForm.get('email').errors?.email"
            >Email is not valid</span
          >
        </div>
      </div>

      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <app-general-input
          label="Phone Number"
          type="text"
          placeholder="+234801234567890"
          controlName="phoneNumber"
          [formGroup]="authForm"
          [required]="true"
        ></app-general-input>
        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('phoneNumber').errors &&
            (authForm.get('phoneNumber').touched ||
              authForm.get('phoneNumber').dirty)
          "
        >
          <span *ngIf="authForm.get('phoneNumber').errors?.required"
            >Phone number is required</span
          >
          <span *ngIf="authForm.get('phoneNumber').errors?.pattern"
            >Phone number must be 11 digits.</span
          >
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'flex items-center justify-center gap-4 mt-4': true }">
      <div class="text-[#5D5E5E] flex gap-2 flex-col relative flex-1">
        <label class="text-sm font-medium">Gender</label>
        <ng-select
          placeholder="Select gender"
          class="custom text-tw-text-color rounded border-[#dcdcdc] text-base bg-[#F4F4F480]"
          formControlName="genderId"
          [loading]="genderQueryResponse.isPending()"
        >
          @for (gender of genderQueryResponse.data()||[]; track gender.id) {
          <ng-option [value]="gender.id">{{ gender.displayField }}</ng-option>

          }
        </ng-select>

        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('genderId').errors &&
            authForm.get('genderId').touched
          "
        >
          <span *ngIf="authForm.get('genderId').errors?.required"
            >Gender is required</span
          >
        </div>
      </div>

      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <app-general-input
          label="Tax Identification Number (TIN) (Optional)"
          type="text"
          placeholder="1234567"
          controlName="tin"
          [formGroup]="authForm"
          [required]="false"
        ></app-general-input>
        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('tin').errors &&
            (authForm.get('tin').touched || authForm.get('tin').dirty)
          "
        >
          <span *ngIf="authForm.get('tin').errors?.required"
            >Field is required</span
          >
          <span *ngIf="authForm.get('tin').errors?.pattern"
            >Unique identifier must be 11 digits.</span
          >
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'flex items-center justify-center gap-4 mt-4': true }">
      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <div class="text-[#5D5E5E] flex gap-2 flex-col relative">
          <label class="text-sm font-medium">State</label>
          <ng-select
            placeholder="Select state"
            class="custom text-tw-text-color rounded border-[#dcdcdc] text-base bg-[#F4F4F480]"
            formControlName="stateId"
            [loading]="stateListQueryResponse.isPending()"
            [isOpen]="false"
            [clearable]="false"
          >
            @for (state of stateListQueryResponse.data()||[]; track state.id) {
            <ng-option [value]="state.id">{{ state.displayField }}</ng-option>

            }
          </ng-select>

          <div
            class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
            *ngIf="
              !!authForm.get('stateId').errors &&
              authForm.get('stateId').touched
            "
          >
            <span *ngIf="authForm.get('stateId').errors?.required"
              >State is required</span
            >
          </div>
        </div>
      </div>
      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <!-- <div class="text-[#5D5E5E] flex gap-2 flex-col relative">
          <label class="text-sm font-medium">Local Government</label>
          <ng-select
            placeholder="Select local government"
            class="custom text-tw-text-color rounded border-[#dcdcdc] text-base bg-[#F4F4F480]"
            formControlName="lgaId"
            [loading]="lookupData.lgas?.isLoading"
          >
            @for (lga of lookupData.lgas?.data||[]; track lga.id) {
            <ng-option [value]="lga.id">{{ lga.displayField }}</ng-option>

            }
          </ng-select>

          <div
            class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
            *ngIf="
              !!authForm.get('lgaId').errors && authForm.get('lgaId').touched
            "
          >
            <span *ngIf="authForm.get('lgaId').errors?.required"
              >LGA is required</span
            >
          </div>
        </div> -->

        <div class="text-[#5D5E5E] flex gap-2 flex-col relative flex-1">
          <label class="text-sm font-medium">Tax office</label>
          <ng-select
            placeholder="Select tax office"
            class="custom text-tw-text-color rounded border-[#dcdcdc] text-base bg-[#F4F4F480]"
            formControlName="taxStationId"
            [loading]="taxOfficesQueryResponse.isPending()"
          >
            @for (taxOffice of taxOfficesQueryResponse.data()||[]; track
            taxOffice.id) {
            <ng-option [value]="taxOffice.id">{{
              taxOffice.displayField
            }}</ng-option>

            }
          </ng-select>

          <div
            class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
            *ngIf="
              !!authForm.get('taxStationId').errors &&
              authForm.get('taxStationId').touched
            "
          >
            <span *ngIf="authForm.get('taxStationId').errors?.required"
              >Tax office is required</span
            >
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'flex items-center justify-center gap-4 mt-4': true }">
      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <app-general-input
          label="Address"
          type="text"
          placeholder="Enter Address"
          controlName="addressLine1"
          [formGroup]="authForm"
        ></app-general-input>
        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('addressLine1').errors &&
            (authForm.get('addressLine1').touched ||
              authForm.get('addressLine1').dirty)
          "
        >
          <span *ngIf="authForm.get('addressLine1').errors?.required"
            >Address is required</span
          >
        </div>
      </div>
      <div class="text-[#5D5E5E] flex flex-col relative flex-1">
        <app-general-input
          label="NIN"
          type="text"
          placeholder="Enter NIN"
          controlName="nin"
          [formGroup]="authForm"
          [required]="true"
        ></app-general-input>
        <div
          class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
          *ngIf="
            !!authForm.get('nin').errors &&
            (authForm.get('nin').touched || authForm.get('nin').dirty)
          "
        >
          <span *ngIf="authForm.get('nin').errors?.required"
            >NIN is required</span
          >
          <span *ngIf="authForm.get('nin').errors?.pattern"
            >NIN must be 11 digits.</span
          >
        </div>
      </div>
    </div>
    <div
      [ngClass]="{
        'hidden items-start justify-center gap-4 mt-4': true,
        '!flex': !!authForm.get('password') && !!authForm.get('confirmPassword')
      }"
      *ngIf="!!authForm.get('password') && !!authForm.get('confirmPassword')"
    >
      <div class="flex w-full gap-4">
        <div class="text-[#5D5E5E] flex flex-col relative flex-1 gap-3">
          <app-password-input
            label="Password"
            placeholder="Enter password"
            controlName="password"
            [formGroup]="authForm"
            [isRequired]="
              authForm.get('password')?.touched &&
              authForm.get('password')?.errors?.required
            "
          ></app-password-input>
          <!-- <div [ngClass]="{ 'flex items-start gap-3': true }">
            <span>
              <svg-icon
                src="assets/svgs/check-circle.svg"
                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                [ngClass]="{
                  '[&_path]:fill-red-500':
                    !authForm.get('password')?.pristine &&
                    !!authForm.get('password')?.errors,
                  '[&_path]:fill-[#00ad5b]':
                    !authForm.get('password')?.pristine &&
                    !authForm.get('password')?.errors
                }"
              ></svg-icon>
            </span>
            <div class="text-[12px] pl-[5px] text-[#555555] font-bold">
              Password must contain at least one
              <span
                [ngClass]="{
      'text-red-500 [&_svg_path]:fill-red-500 border-b border-red-500':
        !authForm.get('password')?.pristine &&
        authForm.get('password')?.errors?.upper,
      'text-[#00ad5b] [&_svg_path]:fill-[#00ad5b] border-b border-[#00ad5b]':
        !authForm.get('password')?.pristine &&
        !authForm.get('password')?.errors?.upper,
    }"
                >uppercase letter</span
              >,
              <span
                [ngClass]="{
      'text-red-500 [&_svg_path]:fill-red-500 border-b border-red-500':
        !authForm.get('password')?.pristine &&
        authForm.get('password')?.errors?.lower,
      'text-[#00ad5b] [&_svg_path]:fill-[#00ad5b] border-b border-[#00ad5b]':
        !authForm.get('password')?.pristine &&
        !authForm.get('password')?.errors?.lower,
    }"
                >lowercase letter</span
              >,
              <span
                [ngClass]="{
      'text-red-500 [&_svg_path]:fill-red-500 border-b border-red-500':
        !authForm.get('password')?.pristine &&
        authForm.get('password')?.errors?.symbol,
      'text-[#00ad5b] [&_svg_path]:fill-[#00ad5b] border-b border-[#00ad5b]':
        !authForm.get('password')?.pristine &&
        !authForm.get('password')?.errors?.symbol,
    }"
                >special characters</span
              >,
              <span
                [ngClass]="{
      'text-red-500 [&_svg_path]:fill-red-500 border-b border-red-500':
        !authForm.get('password')?.pristine &&
        authForm.get('password')?.errors?.numeric,
      'text-[#00ad5b] [&_svg_path]:fill-[#00ad5b] border-b border-[#00ad5b]':
        !authForm.get('password')?.pristine &&
        !authForm.get('password')?.errors?.numeric,
    }"
                >numeric character</span
              >
              &
              <span
                [ngClass]="{
      'text-red-500 [&_svg_path]:fill-red-500 border-b border-red-500':
        !authForm.get('password')?.pristine &&
        authForm.get('password')?.errors?.length,
      'text-[#00ad5b] [&_svg_path]:fill-[#00ad5b] border-b border-[#00ad5b]':
        !authForm.get('password')?.pristine &&
        !authForm.get('password')?.errors?.length,
    }"
                >must be atleast 8 characters long</span
              >
            </div>
          </div> -->
        </div>
      </div>
      <div class="flex w-full gap-4">
        <div class="text-[#5D5E5E] flex flex-col relative flex-1 gap-3">
          <app-password-input
            label="Confirm password"
            placeholder="Confirm password"
            controlName="confirmPassword"
            [formGroup]="authForm"
            [isRequired]="
              authForm.get('confirmPassword')?.touched &&
              authForm.get('confirmPassword')?.errors?.required
            "
          ></app-password-input>
          <div [ngClass]="{ 'flex items-start gap-3': true }">
            <span>
              <svg-icon
                src="assets/svgs/check-circle.svg"
                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
                [ngClass]="{
                  '[&_path]:fill-red-500':
                    !authForm.get('confirmPassword')?.pristine &&
                    !!authForm.get('confirmPassword')?.errors,
                  '[&_path]:fill-[#00ad5b]':
                    !authForm.get('confirmPassword')?.pristine &&
                    !authForm.get('confirmPassword')?.errors
                }"
              ></svg-icon>
            </span>
            <div class="text-[12px] pl-[5px] text-[#555555] font-bold">
              <span
                [ngClass]="{
      'text-red-500 border-b border-red-500':
        !authForm.get('confirmPassword')?.pristine &&
        authForm.get('confirmPassword')?.errors?.matches,
      'text-[#00ad5b] border-b border-[#00ad5b]':
        !authForm.get('confirmPassword')?.pristine &&
        !authForm.get('confirmPassword')?.errors?.matches,
    }"
                >Both passwords must match</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'flex justify-end': true }">
      <button
        appGeneralButton
        [ngClass]="{ 'max-w-[188px] capitalize': true }"
        [type]="'submit'"
      >
        Continue
      </button>
    </div>
  </form>
</div>
