import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { GenerateInvoicePageComponent } from '../../../pages/user-protected/generate-invoice-page/generate-invoice-page.component';
import { GeneralButtonDirective } from '../../directives/buttons/general-button.directive';
import { SvgIconComponent } from 'angular-svg-icon';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { triggerInvoiceDownload } from '../../../core/store/actions/invoice.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-invoice',
  standalone: true,
  imports: [
    CommonModule,
    GenerateInvoicePageComponent,
    GeneralButtonDirective,
    SvgIconComponent,
  ],
  templateUrl: './view-invoice.component.html',
  styleUrl: './view-invoice.component.css',
  host: {
    class: 'relative block',
  },
})
export class ViewInvoiceComponent {
  @Input({ required: true }) billReference: string;
  @Output() onStepChange: EventEmitter<number> = new EventEmitter();

  router = inject(Router);
  store = inject(Store<AppState>);

  handleDownload(arg?: boolean) {
    this.store.dispatch(
      triggerInvoiceDownload({
        data: true,
      })
    );
  }

  proceedToPaymentHandler() {
    this.onStepChange.emit(6);
    this.router.navigate([], {
      queryParams: { view: 'payment' },
      queryParamsHandling: 'merge',
    });
  }
}
