import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { lastValueFrom } from 'rxjs';
import { ObjectLiteral } from '../models/ObjectLiteral';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private appService: AppService) {}
  async generatePaymentLink(data: ObjectLiteral): Promise<any[]> {
    return lastValueFrom(
      this.appService.post(`${APIConstant.payment.getPaymentLink}`, data)
    );
  }
  async getPaymentHistory(params?: ObjectLiteral): Promise<ObjectLiteral> {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.payment.getUserPaymentHistory}`,
        params
      )
    );
  }
  async getPaymentHistoryForDownload(
    params?: ObjectLiteral
  ): Promise<ObjectLiteral> {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.payment.getUserPaymentHistoryForDownload}`,
        params
      )
    );
  }
}
