import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { lastValueFrom } from 'rxjs';
import { IPreRegisterUserDataProps } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private appService: AppService) {}
  async userPreregistrationData(nin: number | string): Promise<any> {
    return lastValueFrom(
      this.appService.get(`${APIConstant.user.preregistration}`, { nin })
    );
  }
  async preRegisterUser(data: IPreRegisterUserDataProps): Promise<any[]> {
    return lastValueFrom(
      this.appService.post(`${APIConstant.user.preRegisterUser}`, data)
    );
  }

  getAllUsers(params: {
    pageNumber: number;
    pageSize: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) {
    return this.appService.get(`${APIConstant.user.getAllUsers}`, params);
  }

  getOneUserById(params: { id: string }) {
    return this.appService.get(`${APIConstant.user.getOneUserById}`, params);
  }

  getUserByTPUI(params: { tpui: string }) {
    return lastValueFrom(
      this.appService.get(`${APIConstant.user.getUserByTPUI}`, params)
    );
  }

  deleteUsers(data: { userIds: string[] }) {
    return this.appService.post(`${APIConstant.user.deleteUsers}`, data);
  }
}
