<div>
  <div
    [ngClass]="{
      'flex items-center justify-center flex-col gap-6 text-[#717272]': true
    }"
  >
    <img
      ngSrc="/assets/images/invoice_success.png"
      width="130"
      height="130"
      priority
      [ngClass]="{ 'w-[130px] h-[130px]': true }"
    />
    <p [ngClass]="{ ' text-2xl font-bold': true }">Invoice Generated</p>
    <div
      [ngClass]="{ 'flex flex-col gap-3 items-center justify-center': true }"
    >
      <p [ngClass]="{ 'text-sm': true }">Amount</p>
      <p [ngClass]="{ ' text-2xl font-bold': true }">
        NGN {{ formatNumber(totalAmount || +amt()) }}
      </p>
    </div>
  </div>

  <div
    [ngClass]="{
      'text-[#717272] pt-10 shadow-[-40px_40px_60px_0px_rgba(190,189,189,0.25)] pb-8': true
    }"
  >
    <p [ngClass]="{ 'capitalize text-sm text-center': true }">
      view invoice to see more details
    </p>
    <div [ngClass]="{ 'flex items-center justify-center gap-9 pt-9': true }">
      <button
        [ngClass]="{
          'capitalize font-lexend text-base text-[#292929] bg-[#F7F7F7] rounded-lg py-4 px-12': true
        }"
        (click)="viewInvoiceHandler()"
      >
        view invoice</button
      ><button
        appGeneralButton
        [ngClass]="{ 'capitalize max-w-[192px] font-lexend py-4': true }"
        (click)="proceedToPaymentHandler()"
      >
        proceed to payment
      </button>
    </div>
  </div>

  <div [ngClass]="{ 'py-4 text-center': true }">
    <p [ngClass]="{ 'text-center': true }">
      <a
        [routerLink]="['/', 'user', 'dashboard']"
        class="font-lexend text-[#2B4E4C] underline text-center"
      >
        Go to Dashboard
      </a>
    </p>
  </div>
</div>
