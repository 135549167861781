<div class="min-w-[618px] mx-auto min-h-[526px] bg-white rounded-[20px] p-8">
    <!-- Title Section -->
    <div class="w-full">
        <div class="relative flex justify-center w-full pb-8 text-center">
            <img [src]="'assets/images/icon-logo.png'" class="w-[50px] overflow-hidden object-fill" />
        </div>

        <div [ngClass]="{'flex flex-col items-center pb-8 gap-2': true}">
            <h3 [ngClass]="{'text-[#292929] text-2xl font-bold': true}">Reset Password</h3>
            <p [ngClass]="{'text-tw-text-color text-base max-w-[318px] mx-auto text-center': true}">Reset your password
                securely and
                regain access to your
                account.
            </p>
        </div>
    </div>

    <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="flex flex-col flex-1 w-full p-4">

        <div class="flex flex-col gap-6 pb-6">

            <div class="w-full mx-auto">
                <label for="" class="flex flex-col">

                    <div class="relative flex w-full">
                        <app-password-input label="New Password" placeholder="Enter new password" controlName="password"
                            [formGroup]="authForm"></app-password-input>

                        <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                            *ngIf="!!authForm.get('password').errors && authForm.get('password').touched">
                            <span *ngIf="authForm.get('password').errors?.required">Field is required</span>
                        </div>
                    </div>
                </label>
            </div>
            <div class="w-full mx-auto">
                <label for="" class="flex flex-col">

                    <div class="relative flex w-full">
                        <app-password-input label="Repeat password" placeholder="both passwords must match"
                            controlName="confirmPassword" [formGroup]="authForm"></app-password-input>

                        <div class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                            *ngIf="!!authForm.get('confirmPassword').errors && authForm.get('confirmPassword').touched">
                            <span *ngIf="authForm.get('confirmPassword').errors?.required">Field is required</span>
                        </div>
                    </div>
                </label>
            </div>

        </div>
        <div class="flex gap-6 mt-auto text-center">
            <button [ngClass]="{'rounded-lg border border-[#DCDCDC] py-4 flex-1': true}"
                (click)="goBack()">Cancel</button>
            <button appGeneralButton type="submit" [disabled]="mutation.isPending()"
                [ngClass]="{'opacity-50': mutation.isPending(),'flex-1 capitalize':true}">{{mutation.isPending()?"Resetting
                password.
                Please
                wait...":"Reset password"}}</button>
        </div>
    </form>
</div>
